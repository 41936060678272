import { HStack, VStack, Text } from "@chakra-ui/layout";
import { Link } from "gatsby";
import * as React from "react";
import Book3D from "./book3D";

const Feature = (props) => {
  return (
    <VStack
      alignItems="start"
      spacing="8"
      width="100%"
      backgroundColor="gray.100"
      px="16"
      py="12"
    >
      <HStack width="100%" alignItems="flex-start" wrap="wrap" spacing="12">
        {" "}
        <Book3D gatsbyCover={props.gatsbyCover} title={props.title}></Book3D>
        <VStack alignItems="start" flexBasis="xs" flexGrow="0.5">
          <h3>{props.author}</h3>
          <Text as="h1" pb="0">
            {props.title}
          </Text>
          {props.subtitle && <h2>{props.subtitle}</h2>}
          <Text
            position="relative"
            maxH="32"
            overflowY="hidden"
            _before={{
              content: "''",
              width: "100%",
              height: "100%",
              position: "absolute",
              left: "0",
              top: "0",
              background:
                "linear-gradient(rgba(255,255,255,0) 48px, #edf2f7 120px)",
            }}
          >
            {props.abstract}
          </Text>
          <Link color="red.600" to={props.slug}>
            mehr
          </Link>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default Feature;

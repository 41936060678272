import * as React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Books from "../components/allBooks";
import Feature from "../components/feature";

// markup
const IndexPage = ({ data }) => {
  const books = data.allMarkdownRemark.nodes;
  const tipp = data.allMarkdownRemark.nodes[0];
  const tippGatsbyCover = getImage(tipp.frontmatter.cover);
  const pufoBooks = data.allMarkdownRemark.nodes.filter((book) =>
    book.frontmatter.tags.includes("Pufo-Bestseller")
  );

  console.log(pufoBooks);

  return (
    <Layout title="Herakles Verlag">
      <Feature
        gatsbyCover={tippGatsbyCover}
        author={tipp.frontmatter.author}
        title={tipp.frontmatter.title}
        subtitle={tipp.frontmatter.subtitle}
        abstract={tipp.frontmatter.abstract}
        slug={tipp.fields.slug}
      ></Feature>
      <Books books={pufoBooks} headline="Bestseller" slider></Books>
      <Books books={books} headline="Alle Bücher" showFilter></Books>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage {
    allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "book" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          author
          abstract
          tags
          cover {
            childImageSharp {
              gatsbyImageData(
                width: 154
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          readingSample
        }
        fields {
          slug
        }
      }
    }
  }
`;

import { Box } from "@chakra-ui/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";

const Book3D = (props) => {
  return (
    <Box position="relative" width="48" height="72">
      <Box
        position="absolute"
        top="-10px"
        left="-17px"
        transform="perspective(50em) rotateY(-25deg) translateZ(-100px)"
        width="156px"
        height="278px"
        borderRadius="2"
        shadow="1px 8px 24px rgba(0,0,50,0.2)"
        as={GatsbyImage}
        image={props.gatsbyCover}
        alt={props.title}
      ></Box>
      <Box
        position="absolute"
        backgroundColor="gray.100"
        width="26px"
        height="260px"
        transform="perspective(50em) rotateY(60deg)"
        left="145px"
        top="-1px"
        shadow="3px 0px 2px 0px rgba(0,0,0,0.1)"
      ></Box>
      <Box
        position="absolute"
        transform="perspective(50em) rotateY(-25deg)"
        width="156px"
        height="256px"
        borderRadius="2"
        shadow="0 0px 2px 0px rgba(0,0,0,0.1)"
        as={GatsbyImage}
        image={props.gatsbyCover}
        alt={props.title}
      ></Box>
      <Box
        position="absolute"
        zIndex="4"
        backgroundColor="white"
        opacity="0.3"
        width="1px"
        height="244"
        left="12px"
        top="6px"
        shadow="1px 0px 1px 0px rgba(0,0,0,0.3)"
      ></Box>
    </Box>
  );
};

export default Book3D;
